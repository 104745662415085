// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dokumentacja-js": () => import("./../../../src/pages/dokumentacja.js" /* webpackChunkName: "component---src-pages-dokumentacja-js" */),
  "component---src-pages-firma-js": () => import("./../../../src/pages/firma.js" /* webpackChunkName: "component---src-pages-firma-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pd-refractories-js": () => import("./../../../src/pages/pd-refractories.js" /* webpackChunkName: "component---src-pages-pd-refractories-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-produkt-js": () => import("./../../../src/pages/produkt.js" /* webpackChunkName: "component---src-pages-produkt-js" */),
  "component---src-pages-produkty-js": () => import("./../../../src/pages/produkty.js" /* webpackChunkName: "component---src-pages-produkty-js" */)
}

